#Account {
    padding-bottom: 24px;

    .lab {
        margin-bottom: 50px;
        display: flex;
        align-items: center;

        &>div:nth-child(1) {
            color: #333333;
            font-size: 14px;
            font-weight: Bold;
            width: 125px;
        }

        &>div:nth-child(2) {
            width: 210px;
        }

        &>div:nth-child(3) {
            color: #F7385B;
            cursor: pointer;
        }
    }

    .ant-input[disabled] {
        color: #333333;
    }   

    #InfluencerAccount {
        .ant-select-disabled {
            span {
                color: #333333 !important;
            }
        }
    }
}