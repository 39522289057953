
.customer{
    width: 40px; height: 40px; background-color: #1992F1; padding: 12px 0;
    position: fixed; right: 10px; bottom: 157px;
    text-align: center; border-radius: 3px; cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10000;
}
.customer>img{
    width: 42px; margin-top: -6px;
}

.customers{
    width: 40px; height: 40px; background-color: #1992F1; padding: 5px 4px;
    position: fixed; right: 10px; bottom: 112px;
    text-align: center; border-radius: 3px; cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10000;
}
.customers>img{
    width: 20px; margin-top: 5px;
}
.zhiding{
    width: 40px; height: 40px; background-color: #FFFFFF; padding: 8px 4px;
    position: fixed; right: 10px; bottom: 66px;
    text-align: center; border-radius: 3px; cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10000;
}
.zhiding>img{
    width: 14px;
}

.kefu{
    width: 180px; text-align: center; display: none;
    position: absolute; left: -191px; top: -31px; padding: 20px 0;
    background: #FFFFFF; border-radius: 5px;
    box-shadow: 0px 0px 8px 0px #E2E2E2;
}
.kefu>div{
    width: 100%; padding-right: 4px; line-height: 18px;
    position: relative;
}
.kefu>div>p{
    color: #333333; font-size: 14px; font-weight: bold; margin-bottom: 5px;
}
.kefu .sanjiao{
    position: absolute; right: -14px; top: 14px; 
    transform: rotate(180deg);
}
.kefus{
    width: 229px; text-align: right; font-weight: 500;
    position: absolute; left: -240px; top: -35px; padding: 10px 15px;
    background: #FFFFFF; line-height: 13px; font-size: 12px;
    box-shadow: 0px 0px 8px 0px #E2E2E2;
    border-radius: 5px; color: #909090; display: none;
}
.kefus>div>div{
    width: calc(100% - 83px); padding-right: 4px; line-height: 15px;
    position: relative;
}
.kefus>div>div>p{
    color: #333333; font-size: 14px; font-weight: bold; margin-bottom: 5px;
}
.kefus .sanjiao{
    position: absolute; right: -29px; top: 10px; 
    transform: rotate(180deg);
}
.kefus>div>img{
    width: 83px;
}
.customers:hover .kefu{
    display: block;
}
.customers:hover>img{
    display: none;
}
.customers:hover>div{
    display: block!important;
}
.customer:hover .kefus{
    display: block;
}
.customer:hover>img{
    display: none;
}
.customer:hover>div{
    display: block!important;
}




