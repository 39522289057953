* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-popover-inner {
  border-radius: 6px;
  overflow: hidden;
}

.JoL-multifunction>div {
  line-height: 20px !important;
}

.JoL-multifunction-multiple {
  line-height: 20px !important;
}

.current-time {
  color: red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.flex {
  display: flex;
}

.flex-ac {
  display: flex;
  align-items: center;
}

.flex-jc {
  display: flex;
  justify-content: center;
}

.flex-ja {
  display: flex;
  justify-content: space-around;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex-acjc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-acja {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-acjb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-acjs{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
}
.pos-center{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.pos-bot{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.pos-left{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
