#VideoDemand {

    .LDactive {
        color: #F7385B;
        background-color: #FFEEF1;
    }

    .blogerBox {
        background: url(../img//bg2.png) no-repeat;
        background-size: 100% 100%;
        padding: 40px 24px;
        height: 112px;
        display: flex;
    }

    .blogerBoxBottom {
        display: flex;
        text-align: center;
        justify-content: space-between;

        &>div {
            width: 23%;

            &>div:first-child {
                font-family: neue;
                font-weight: 800;
            }

            &>div:last-child {
                font-size: 12px;
                color: #888888;
            }
        }
    }
}