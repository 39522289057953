#GoodsRankingDetail {
    .top {
        padding: 40px 40px 26px;

        .flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }


        .img_box {
            border: #fff 1px solid;
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .active {
            border-color: #F7385B;

        }
    }

    /deep/.sku-modal {

        .img_box {
            border: #fff 1px solid;
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .active {
            border-color: #F7385B;

        }

        .size_box {
            border: 1px solid #eee;
            padding: 6px 15px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            color: #333;
            background: #fff;
        }

        .size_box_active {
            border-color: #F7385B;
            background: #FFEEF1;
            color: #F7385B;
        }
    }

    .bottom {
        text-align: center;
        padding: 0 24px 28px;
        background: #fff;
        box-shadow: 0 5px 10px -5px #F6F6F6 inset;
    }

    .shop-des{
        text-align: center;
    }

    .sd-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .sd-title::before {
        content: open-quote;
        width: 4px;
        height: 20px;
        background: #F7385B;
        color: #F7385B;
        margin-right: 10px;
        border-radius: 3px;
    }

    .info-tips-box {
        //position: absolute;
        //left: 0;
        //top: 0;

        .info-tips {
            background: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/Sensation/top1.png) no-repeat 100% 100%;
            width: 125px;
            height: 40px;
            line-height: 40px;
            padding-left: 20px;
            color: #fff;
        }
    }

    .trapezoid-box{
        display: flex;
    }
    .trapezoid-left{
        background-image: linear-gradient(180deg, #F7385B, #FF716E) ;

    }
    .trapezoid-right{
        height: 50px;
        width: 30px;
        background-image: linear-gradient(180deg, #F7385B, #FF716E);
        clip-path: polygon(0 0%,0% 100%,100% 0%,100% 0%);
        margin-left: -1px;
        border-bottom-left-radius: 4px;
    }
}