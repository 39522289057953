#MusicRankingDetail {

  .bottom {
    padding: 0 18px 60px;
    margin: 0 24px;

    > div {
      margin-top: 26px;
    }

    > div:first-child {
      margin-top: 0
    }

    .title span {
      color: #333333;
      font-weight: bold;
    }
  }

  .sd-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .sd-title::before {
    content: open-quote;
    width: 4px;
    height: 20px;
    background: #F7385B;
    color: #F7385B;
    margin-right: 10px;
    border-radius:3px;
  }

}