#BuyVip {
    padding-left: 100px;
    color: #333;
    letter-spacing: 1px;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1420px;

        &>div:nth-child(1) {
            font-size: 18px;
            font-weight: 800;
        }

        &>div:nth-child(1)::before {
            content: "1";
            width: 4px;
            height: 20px;
            background-color: #F7385B;
            color: #F7385B;
            margin-right: 10px;
        }

        &>div:nth-child(2) {
            font-size: 14px;
            color: #666;
            font-weight: 800;
        }
    }

    .middle {
        background: #FFF;
        margin-top: 20px;
        width: 1420px;
        padding: 45px 0 110px 50px;
    }

    .bottom {
        background: #333333;
        padding: 0 50px;
        width: 1420px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 1700px) {
    #BuyVip {
        padding-left: 40px;
        color: #333;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1320px;

            &>div:nth-child(1) {
                font-size: 18px;
                font-weight: 800;
            }

            &>div:nth-child(1)::before {
                content: "1";
                width: 4px;
                height: 20px;
                background-color: #F7385B;
                color: #F7385B;
                margin-right: 10px;
            }

            &>div:nth-child(2) {
                font-size: 14px;
                color: #666;
                font-weight: 800;
            }
        }

        .middle {
            background: #FFF;
            margin-top: 20px;
            width: 1320px;
            padding: 45px 0 80px 50px;
        }

        .bottom {
            background: #333333;
            padding: 0 50px;
            width: 1320px;
            height: 90px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}