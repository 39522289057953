#Member {
    letter-spacing: 1px;
    min-width: 1361px;
    font-size: 16px;

    .top {
        width: 100%;
        min-width: 1100px;
        height: 560px;
        background-image: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/bg.png);
        background-position: center;
        position: relative;
        padding-top: 62px;

        &>.content {
            display: flex;
            justify-content: space-between;
            padding: 0px 60px 0;
            position: absolute;
            bottom: 0;
            width: 100%;

            .box {
                width: 440px;
                height: 391px;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding-top: 188px;

                &>div:nth-child(1) {
                    color: #333;
                    font-weight: 800;
                    font-size: 24px;
                }

                &>div:nth-child(2) {
                    color: #333;
                    font-size: 24px;
                    margin-top: -2px;
                    font-family: 'sans-medium';

                    &>span:nth-child(1) {
                        font-family: 'neue';
                        font-weight: 600;
                    }

                    &>span:nth-child(2) {
                        font-family: 'neue';
                        font-weight: 800;
                        font-size: 50px;
                    }
                }

                &>div:nth-child(3) {
                    width: 175px;
                    height: 44px;
                    border-radius: 44px;
                    background: #fff;
                    margin-top: 22px;
                    font-weight: 800;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                        margin-left: 14px;
                    }
                }
            }

            .box:nth-child(1) {
                background-image: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/v3.png);
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: 0 100.5%;
            }

            .box:nth-child(2) {
                background-image: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/v2.png);
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: 0 100.5%;
            }

            .box:nth-child(3) {
                background-image: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/v3.png);
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: 0 100.5%;
            }
        }
    }

    .list {
        display: flex;
        justify-content: space-between;
        padding: 0 60px 0;
        width: 100%;
        min-width: 1100px;
        margin-bottom: 60px;

        .list_box {
            width: 440px;
            padding: 40px 0 40px 74px;
            background: #FFF;

            .title {
                color: #333;
                font-size: 18px;
                font-weight: 900;
                margin-top: 20px;
                margin-bottom: 10px;
                position: relative;
            }

            .title:nth-child(1) {
                margin-top: 0;
            }

            .title:nth-child(1)::before {
                content: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/hz.png);
                position: absolute;
                left: -30px;
                top: 2px;
            }

            .title:nth-child(3)::before {
                content: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/ph.png);
                position: absolute;
                left: -30px;
                top: 2px;
            }

            .title:nth-child(5)::before {
                content: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/sp.png);
                position: absolute;
                left: -30px;
                top: 2px;
            }

            .title:nth-child(7)::before {
                content: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/sc.png);
                position: absolute;
                left: -30px;
                top: 2px;
            }

            .title:nth-child(9)::before {
                content: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/sy.png);
                position: absolute;
                left: -30px;
                top: 2px;
            }

            .content {
                color: #666;
                display: flex;
                margin-bottom: 8px;

                &>div:nth-child(1) {
                    width: 222px;
                    font-family: 'sans-medium';
                    flex-shrink: 0;
                }

                &>div:nth-child(2) {
                    width: 200px;
                    font-family: 'sans-medium';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (max-width: 1900px) {
    #Member {
        font-size: 15px;

        .top {
            height: 560px*0.95;

            &>.content {
                .box {
                    width: 440px*0.95;
                    height: 391px*0.95;
                    padding-top: 182px;

                    &>div:nth-child(1) {
                        color: #333;
                        font-weight: 800;
                        font-size: 22px;
                    }

                    &>div:nth-child(2) {
                        color: #333;
                        font-size: 24px;
                        margin-top: -2px;
                        font-family: 'sans-medium';

                        &>span:nth-child(1) {
                            font-family: 'neue';
                            font-weight: 600;
                        }

                        &>span:nth-child(2) {
                            font-family: 'neue';
                            font-weight: 800;
                            font-size: 45px;
                        }
                    }

                    &>div:nth-child(3) {
                        width: 175px;
                        height: 40px;
                        border-radius: 40px;
                        background: #fff;
                        margin-top: 20px;
                        font-weight: 800;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        img {
                            margin-left: 14px;
                        }
                    }
                }
            }
        }

        .list {
            .list_box {
                width: 440px*0.95;
            }
        }
    }
}

@media screen and (max-width: 1800px) {
    #Member {
        font-size: 14px;

        .top {
            height: 560px*0.88;

            &>.content {
                .box {
                    width: 440px*0.88;
                    height: 391px*0.88;
                    padding-top: 170px;

                    &>div:nth-child(1) {
                        color: #333;
                        font-weight: 800;
                        font-size: 22px;
                    }

                    &>div:nth-child(2) {
                        color: #333;
                        font-size: 24px;
                        margin-top: -2px;
                        font-family: 'sans-medium';

                        &>span:nth-child(1) {
                            font-family: 'neue';
                            font-weight: 600;
                        }

                        &>span:nth-child(2) {
                            font-family: 'neue';
                            font-weight: 800;
                            font-size: 42px;
                        }
                    }

                    &>div:nth-child(3) {
                        width: 175px;
                        height: 40px;
                        border-radius: 40px;
                        background: #fff;
                        margin-top: 18px;
                        font-weight: 800;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        img {
                            margin-left: 14px;
                        }
                    }
                }
            }
        }

        .list {
            .list_box {
                width: 440px*0.88;
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    #Member {
        font-size: 14px;

        .top {
            height: 560px*0.88;

            &>.content {
                .box {
                    width: 440px*0.88;
                    height: 391px*0.88;
                    padding-top: 165px;

                    &>div:nth-child(1) {
                        color: #333;
                        font-weight: 800;
                        font-size: 22px;
                    }

                    &>div:nth-child(2) {
                        color: #333;
                        font-size: 24px;
                        margin-top: -2px;
                        font-family: 'sans-medium';

                        &>span:nth-child(1) {
                            font-family: 'neue';
                            font-weight: 600;
                        }

                        &>span:nth-child(2) {
                            font-family: 'neue';
                            font-weight: 800;
                            font-size: 42px;
                        }
                    }

                    &>div:nth-child(3) {
                        width: 175px;
                        height: 40px;
                        border-radius: 40px;
                        background: #fff;
                        margin-top: 18px;
                        font-weight: 800;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        img {
                            margin-left: 14px;
                        }
                    }
                }
            }
        }

        .list {
            .list_box {
                width: 440px*0.88;
            }
        }
    }
}