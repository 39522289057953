#SensationDetail {
    display: flex;

    .sd-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .sd-title::before {
        content: '1';
        width: 4px;
        height: 20px;
        background: #F7385B;
        color: #F7385B;
        margin-right: 10px;
    }

    .information {
        width: 330px;

        .info-box {
            background-color: #fff;
            padding: 20px;
            padding-top: 89px;
            border-radius: 5px;

            .info-tips-box {
                position: absolute;
                left: 0;
                top: 0;

                .info-tips {
                    background: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/Sensation/top1.png) no-repeat 100% 100%;
                    width: 125px;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 20px;
                    color: #fff;
                }
            }

            .target-list {
                display: flex;
                justify-content: space-around;
                width: 100%;
                margin-top: 22px;

                div {
                    background-color: #FFE7E9;
                    color: #F7385B;
                    width: 88px;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 5px;
                }
            }
        }
    }

    #analysis {
        .time-box {
            display: flex;

            .time-item {
                width: 60px;
                height: 30px;
                cursor: pointer;
                line-height: 30px;
                text-align: center;
                color: #CCCCCC;
                background: #F6F6F6;
            }

            .time-item-active {
                color: #F7385B;
                background: #FFEEF1;
            }
        }
    }
}