#EnterpriseCertification {
    .title {
        font-weight: bold;
        padding-left: 16px;
        margin-bottom: 10px;
        position: relative;
    }

    .title::before {
        content: '*';
        color: #F7385B;
        position: absolute;
        left: 0;
        top: 2px;
    }

    .ant-select-selector {
        height: 48px;
    }

    .ant-select-single .ant-select-selection-item {
        line-height: 46px;
    }

    .ant-select-selection-placeholder {
        line-height: 46px;
    }
}