#MyGoods {
    .tabs {
        width: 100%; height: 30px; margin-top: 12px;
        display: flex; font-weight: 500;
        padding: 0 20px; color: #666;
        // background: #fff;
        position: relative;

        div {
            // line-height: 60px;
            margin-right: 50px;
            cursor: pointer;
        }

        .active {
            border-bottom: 2px #F7385B solid;
            color: #F7385B;
        }
    }

    .search {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center; border-radius: 5px;
        padding: 0 24px; padding-top: 15px;
        background: #fff;
        position: relative;
        margin-top: 15px;
    }

    .goods_main {
        background: #fff;
        width: 100%;

        &>div:nth-child(1) {
            display: flex;
            align-items: center;
            padding: 0px 24px 24px 24px;
        }

        &>div:nth-child(2) { background-color: #F6F6F6;
            display: flex;
            align-items: center;
            padding: 13px 24px 13px 24px;
        }


    }

    .list {
        color: #333;
        margin-top: 1px;
        width: 100%;

        &>div {
            padding: 20px 20px 13px 24px;

            .line {
                display: flex;
                align-items: center;
            }
        }
    }

    .btn {
        background: #F6F6F6;
        color: #CCCCCC;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        padding: 3px 16px;
        cursor: not-allowed;
    }

    .btn_active {
        background: #FFEEF1;
        color: #F7385B;
        border: 1px solid #F7385B;
        border-radius: 5px;
        padding: 3px 16px;
    }

    .btn_top {
        cursor: pointer;
        text-align: center;
        color: rgb(255, 255, 255);
        letter-spacing: 2px;
        margin: 0;
        background: linear-gradient(rgb(247, 56, 91), rgb(255, 113, 110));
    }
}