#MyExcellentWorks {
    .tabs {
        width: 100%;
        display: flex;
        padding: 22px 48px;
        background: #fff;
        position: relative;

    }

    .search {
        width: 100%;
        height: 82px;
        display: flex;
        align-items: center;
        padding: 0 48px;
        background: #fff;
        position: relative;
        margin-top: 10px;
    }

    .goods_main {
        background: #fff;
        width: 100%;

        &>div:nth-child(1) {
            display: flex;
            align-items: center;
            padding: 26px 109px 13px 50px;
        }
    }

    .list {
        color: #333;
        margin-top: 1px;
        width: 100%;

        &>div {
            padding: 13px 109px 13px 50px;

            .line {
                display: flex;
                align-items: center;
            }
        }
    }

    .btn {
        background: #F6F6F6;
        color: #CCCCCC;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        padding: 3px 16px;
        cursor: not-allowed;
    }

    .btn_active {
        background: #FFEEF1;
        color: #F7385B;
        border: 1px solid #F7385B;
        border-radius: 5px;
        padding: 3px 16px;
        cursor: pointer;
    }

    .btn_top {
        cursor: pointer;
        text-align: center;
        color: rgb(255, 255, 255);
        letter-spacing: 2px;
        margin: 0;
        background: linear-gradient(rgb(247, 56, 91), rgb(255, 113, 110));
    }

    .fot-btn{
        margin-top: 50px;
    }
    .fot-btn div,.fot-btn button{
        width: 210px;
        height: 40px;
        border: 1px solid transparent;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
    }
    .fot-btn button:first-child{
        color:#F7385B;
        border-color: #F7385B;
        margin: 0 14px;
    }
    .fot-btn button:nth-child(2){
        background: linear-gradient(180deg, #F7385B, #FF716E);
        color:#FFFFFF
    }
}