#BusinessDetail {
    .top {
        background: #fff;
        padding: 24px;
        display: flex;

        .flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .input-position {
            position: relative;
        }

        .img_box {
            border: #fff 1px solid;
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .active {
            border-color: #F7385B;

        }

        .size_box {
            border: 1px solid #eee;
            padding: 6px 15px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            color: #333;
            background: #fff;
        }

        .size_box_active {
            border-color: #F7385B;
            background: #FFEEF1;
            color: #F7385B;
        }
    }

    .bottom {
        padding: 24px;
        background: #fff;
        box-shadow: 0 5px 10px -5px #F6F6F6 inset;

        .sd-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }

        .sd-title::before {
            content: open-quote;
            width: 4px;
            height: 20px;
            background: #F7385B;
            color: #F7385B;
            margin-right: 10px;
            border-radius: 3px;
        }

        .shop_tabs {
            width: 100%;
            background: #F6F6F6;
            line-height: 60px;

            &>div {
                width: 1133px;
                margin: 0 auto;
                display: flex;
                align-items: center;

                &>div {
                    margin-right: 100px;
                    cursor: pointer;
                }

                .shop_tabs_active {
                    color: #F7385B;
                }
            }


        }
    }

    .info-box {
        padding: 20px;
        padding-top: 89px;
        border-radius: 5px;

        .info-tips-box {
            position: absolute;
            left: 0;
            top: 0;

            .info-tips {
                background: url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/Sensation/top1.png) no-repeat 100% 100%;
                width: 125px;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
                color: #fff;
            }
        }

        .target-list {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 22px;

            div {
                background-color: #FFE7E9;
                color: #F7385B;
                width: 88px;
                text-align: center;
                line-height: 24px;
                border-radius: 5px;
            }
        }

        .img_box {
            border: #fff 1px solid;
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            cursor: pointer;
        }
    }
}