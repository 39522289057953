#GoodsDetail {
    padding-bottom: 24px;

    .top {
        background: #fff;
        padding: 24px;
        display: flex;

        .flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .input-position {
            position: relative;
        }

        .img_box {
            border: #fff 1px solid;
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .active {
            border-color: #F7385B;

        }

        .size_box {
            border: 1px solid #eee;
            padding: 6px 15px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            color: #333;
            background: #fff;
        }

        .size_box_active {
            border-color: #F7385B;
            background: #FFEEF1;
            color: #F7385B;
        }
        .No_active {
            border: 1px solid #eee;
            background: #f6f6f6;
            color: #666;
        }

    }

    .bottom {
        padding: 24px;
        background: #fff;
        box-shadow: 0 5px 10px -5px #F6F6F6 inset;

        .sd-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }

        .sd-title::before {
            content: open-quote;
            width: 4px;
            height: 20px;
            background: #F7385B;
            color: #F7385B;
            margin-right: 10px;
            border-radius: 3px;
        }

        .shop_tabs {
            width: 100%;
            background: #F6F6F6;
            line-height: 60px;

            &>div {
                width: 1133px;
                margin: 0 auto;
                display: flex;
                align-items: center;

                &>div {
                    margin-right: 100px;
                    cursor: pointer;
                    position: relative;
                }

                .shop_tabs_active {
                    color: #F7385B;

                    &:after {
                        display: block;
                        content: '';
                        background-color: #F7385B;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        height: 4px;
                        width: 80%;
                        border-radius: 4px;
                    }
                }
            }


        }
    }
}